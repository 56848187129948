import * as React from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CircularProgress, Stack} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import moment from 'moment';
// @ts-ignore
import iOS from 'is-ios';

interface Channel {
    channelId: string
    channelName: string
    subscribedUserId: string
    totalItemCount: string
    uploadsPlaylistId: string
}

interface Video {
    videoUrl: string
    monthPublished: string
    channelId: string
    publishedAt: string
    videoId: string
    title: string
}

const BASE_API = 'https://ytapi.pyme.ch';

export default class extends React.Component {
    state = {
        channelsDataLoaded: false,
        videosDataLoaded: false,
        channels: {},
        videos: []
    };

    getChannelName(channelId: string) {
        try {
            // @ts-ignore
            return this.state.channels[channelId].channelName;
        }
        catch(e) {
            return '';
        }
    }

    componentDidMount() {
        if (!this.state.channelsDataLoaded) {
            axios.get(BASE_API + '/channels')
                .then((res: { data: any; }) => res.data)
                .then((res: Channel[]) => {
                    let channels: {[key: string]: Channel} = {};
                    for (let channel of res) {
                        channels[channel.channelId] = channel;
                    }
                    this.setState({ channels }, () => {
                        this.setState({ channelsDataLoaded: true })
                    });
                });
        }
        if (!this.state.videosDataLoaded) {
            axios.get(BASE_API + '/last7days')
                .then((res: { data: any; }) => res.data)
                .then((res: Video[]) => {
                    this.setState({
                        videos: res.sort((v1, v2) => v1.publishedAt < v2.publishedAt ? 1:-1)
                    }, () => {
                        this.setState({ videosDataLoaded: true })
                    });
                });
        }
    }

    accessToVideo(videoId: string) {
        if (iOS) {
            window.location.assign('youtube://watch?v=' + videoId);
        } else {
            window.location.assign('https://youtube.com/watch?v=' + videoId);
        }
    }

    accessToChannel(channelId: string) {
        if (iOS) {
            window.location.assign('youtube://channel/' + channelId);
        } else {
            window.location.assign('https://youtube.com/channel/' + channelId);
        }
    }

    alignItemsList() {
        return (
            <List sx={{width: '100%', maxWidth: 540}}>
                { this.state.videos.map((v: Video) => (<>
                    <ListItem key={v.videoId} alignItems="flex-start">
                        <ListItemAvatar>
                            <img onClick={() => this.accessToVideo(v.videoId)}
                                 style={{maxWidth: '100px', marginRight: '15px'}}
                                 src={'https://ytimg.pyme.ch/' + v.videoId}
                                 alt={'Thumbnail for video ' + v.title}/>
                        </ListItemAvatar>
                        <ListItemText
                            onClick={() => this.accessToVideo(v.videoId)}
                            primary={v.title}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <span onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            this.accessToChannel(v.channelId)
                                        }}
                                            className="channelName">
                                            {this.getChannelName(v.channelId)}
                                        </span>
                                    </Typography><br/>
                                    <span onClick={event => { event.preventDefault(); event.stopPropagation(); }}>
                                        {moment(v.publishedAt).format('DD.MM.YYYY, HH:mm')}
                                    </span>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                </>)) }
            </List>
        );
    }

    render() {
        if (!this.state.channelsDataLoaded || !this.state.videosDataLoaded) {
            return (
                <Stack mt={8} alignItems="center">
                    <CircularProgress/>
                </Stack>
            );
        }
        return (
            <Container maxWidth="sm">
                <Box sx={{ my: 4 }}>
                    <Typography align="center" variant="h4" component="h1" gutterBottom>
                      yousamf subscriptions
                    </Typography>
                    { this.alignItemsList() }
                </Box>
            </Container>
        );
    }
}
